<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="模板名称:">
                    <el-input
                        class="s-input"
                        v-model="queryParams.name"
                        placeholder="请输入模板名称"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="计价方式:">
                    <el-select
                        v-model="queryParams.ty"
                        placeholder="请选择计价方式"
                        clearable
                        @change="changeQuery"
                    >
                        <el-option label="按件数" :value="1"></el-option>
                        <el-option label="按重量" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="loadData()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增运费模板</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="可配送区域" prop="name" width="200" align="center"></el-table-column>
                <el-table-column label="首件(个)" prop="first" width="200" align="center"></el-table-column>
                <el-table-column label="运费(元)" prop="first_price" width="200" align="center"></el-table-column>
                <el-table-column label="续件(个)" prop="renew" width="200" align="center"></el-table-column>
                <el-table-column label="续费(元)" prop="renew_price" width="200" align="center"></el-table-column>
                <el-table-column label="收费类型" prop="ty" width="150" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">按件数</el-tag>
                        <el-tag type="success" v-if="scope.row.ty === 2">按重量</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑添加运费模板 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="100%">
            <el-row>
                <el-form class="form_box" ref="form" v-bind:rules="rules" :model="formExpressTpl" v-loading.body="loading"
                         label-position="right" label-suffix=":" label-width="120px" size="large"
                         >
                    <el-row>
                        <el-col :span="4">
                            <el-alert
                                title="默认配置"
                                type="success"
                                :closable="false"
                            >
                            </el-alert>
                            <br>
                            <el-form-item label="模板名称" prop="name">
                                <el-input v-model="formExpressTpl.name" placeholder="请输入模板名称"></el-input>
                            </el-form-item>
                            <el-form-item label="首件/首重" prop="first">
                                <el-input v-model="formExpressTpl.first" placeholder="请输入首件/首重数量"></el-input>
                            </el-form-item>
                            <el-form-item label="运费" prop="first_price">
                                <el-input v-model="formExpressTpl.first_price" placeholder="请输入运费"></el-input>
                            </el-form-item>
                            <el-form-item label="续件/续重(kg)" prop="renew">
                                <el-input v-model="formExpressTpl.renew" placeholder="请输入续件/续重数量"></el-input>
                            </el-form-item>
                            <el-form-item label="续费" prop="renew_price">
                                <el-input v-model="formExpressTpl.renew_price" placeholder="请输入续费数量"></el-input>
                            </el-form-item>
                            <el-form-item label="计价方式" prop="ty">
                                <el-radio-group v-model="formExpressTpl.ty">
                                    <el-radio :label="2">按件</el-radio>
                                    <el-radio :label="1">按重</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="模板状态" prop="state">
                                <el-radio-group v-model="formExpressTpl.state">
                                    <el-radio :label="2">可用</el-radio>
                                    <el-radio :label="0">禁用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" style="padding-left: 20px">
                            <el-alert
                                title="勾选包邮区域"
                                type="success"
                                :closable="false"
                            >
                            </el-alert>
                            <br>
                            <el-button @click="resetChecked" size="small" type="danger" style="margin: 0 10px">
                                清空所有包邮区域
                            </el-button>
<!--                            <el-checkbox v-model="cityCheckStrictly"-->
<!--                                         @change="handleCheckedTreeConnect($event, 'menu')">父子联动-->
<!--                            </el-checkbox>-->
                            <el-tree
                                class="tree-border"
                                :data="cityTree"
                                show-checkbox
                                ref="cityTree"
                                :default-expand-all="false"
                                node-key="value"
                                :default-checked-keys="cityMenuIdList"
                                :check-strictly="!cityCheckStrictly"
                                empty-text="加载中，请稍后"
                                :props="treeProps">
                                <div class="custom-tree-node" slot-scope="{ node, data }">
                                    <span>{{ node.label }}</span>
                                    <span>&nbsp;&nbsp;</span>
                                    <span>
                                          <el-button type="text" size="mini"
                                                     @click="() => append2CustomAreaTable(node,data,true)">
                                              <span style="font-size: 18px;font-weight: bold"
                                                    title="添加到定制邮费区域">+</span>
                                              </el-button>
                                            <el-button type="text" size="mini"
                                                       @click="() => append2CustomAreaTable(node,data,false)">
                                                <span style="color: red;font-size: 18px;font-weight: bold"
                                                      title="添加到不发货区域">-</span>
                                            </el-button>
                                        </span>
                                </div>
                            </el-tree>
                        </el-col>
                        <el-col :span="17" style="padding-left: 20px">
                            <el-alert
                                title="定制特殊区域列表，点击左侧【勾选包邮区域】地区项目的右侧按钮  +  - 添加"
                                type="success"
                                :closable="false"
                            >
                            </el-alert>
                            <el-table class="form_table" v-loading="loading" element-loading-text="数据加载中" stripe
                                      border
                                      :data="regionList" @cell-click="cellClick">
                                <el-table-column label="区域" align="center" width="auto">
                                    <template slot-scope="scope">
                                        {{ scope.row.areaName }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否发货" align="center" width="130px">
                                    <template slot-scope="scope">
                                        <el-switch
                                            style="display: block"
                                            v-model="scope.row.isSend"
                                            active-color="#13ce66"
                                            inactive-color="red"
                                            active-text="是"
                                            inactive-text="否">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="计价方式" align="center" width="180px">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="scope.row.ty" :disabled="!scope.row.isSend">
                                            <el-radio :label="2">按件</el-radio>
                                            <el-radio :label="1">按重</el-radio>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column label="首件/首重(kg)" prop="first" width="150" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.isSend" size="small" :disabled="!scope.row.isSend"
                                                  v-model="scope.row.first" placeholder="请输入"
                                                  @blur="loseFcous(scope.$index, scope.row)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="运费(元)" prop="express" width="150" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.isSend" size="small" :disabled="!scope.row.isSend"
                                                  v-model="scope.row.first_price" placeholder="请输入"
                                                  @blur="loseFcous(scope.$index, scope.row)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="续件/续重(kg)" prop="next" width="150" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.isSend" size="small" :disabled="!scope.row.isSend"
                                                  v-model="scope.row.renew" placeholder="请输入"
                                                  @blur="loseFcous(scope.$index, scope.row)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="续费(元)" prop="price" width="150" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.isSend" size="small" :disabled="!scope.row.isSend"
                                                  v-model="scope.row.renew_price" placeholder="请输入"
                                                  @blur="loseFcous(scope.$index, scope.row)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" prop="" width="80" align="center">
                                    <template slot-scope="scope">
                                        <el-button type="text" icon="el-icon-delete-solid" style="color: red"
                                                   @click="regionRemove(scope.$index, scope.row)"></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-form-item>
                                <p>未特殊编辑的地区，按默认设置规则算计</p>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import {request} from '@/common/request'

export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            menuList: ['设置', '运费模板'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            addRegion: false,
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            regionPageInfo: {},
            addVisible: false,
            addVisibles: false,
            title: '添加配送设置',
            rules: {
                name: [
                    { required: true, message: '模板名称不能为空', trigger: 'blur' }
                ],
                first: [
                    { required: true, message: '首件/重不能为空', trigger: 'blur' }
                ],
                first_price: [
                    { required: true, message: '首件/重运费不能为空', trigger: 'blur' }
                ],
                renew:[
                    { required: true, message: '续件/续重不能为空', trigger: 'blur' }
                ],
                renew_price:[
                    { required: true, message: '续件/续重运费不能为空', trigger: 'blur' }
                ],
                state:[
                    { required: true, message: '可用状态不能为空', trigger: 'blur' }
                ],
                ty:[
                    { required: true, message: '计价不能为空', trigger: 'blur' }
                ],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },

            mode: 'add',
            formExpressTpl: {
                countType: 1,
                name: 1,
                ty: 1,
                first:1,
                first_price: 10,
                renew:2,
                renew_price:8,
                state:2,
            },
            all_checked: false,
            regionList: [
               /* {
                    areaName:'',
                    areaCode:'',
                    isSend:true,
                    ty:0,
                    cityId:"",
                    first:1,
                    first_price: 10,
                    renew:2,
                    renew_price:8,
                }*/
            ],
            treeProps:{
                children: "children",
                label: "label"
            },
            cityMenuList:[],   //单个角色的所有菜单的容器
            cityMenuIdList:[

            ],   //单个角色的所有菜单id的容器
            cityCheckStrictly:true,
            cityTree:null,
            currProvinceIndex:null, // 当前操作的省的索引
            currCityIndex:null, //当前操作的市的索引
        }
    },
    created() {
        this.loadData();
        this.loadCityTree()
    },
    methods: {



        // 城市树（父子联动）
        handleCheckedTreeConnect(value) {
            this.cityCheckStrictly = value ? true : false;
        },

        resetChecked() {
            this.$refs.cityTree.setCheckedKeys([]);
        },

        // 所有菜单节点数据
        getCityAllCheckedKeys() {
            // 目前被选中的菜单节点
            let checkedKeys = this.$refs.cityTree.getCheckedKeys();
            // 半选中的菜单节点
            let halfCheckedKeys = this.$refs.cityTree.getHalfCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },

        loadData() {
            // this.loading = true;
            //配送设置列表
            request.get('/common/express/template/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    for (const tpl of this.pageInfo.list) {
                        tpl.freeAreaCodes =JSON.parse(tpl.free_area_codes)

                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadCityTree(){
            // this.loading = true;
            //配送设置列表
            request.post('/system/city/tree', {level:1}).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.cityTree = ret.data;
                    console.log("---------cityTree--------")
                    console.log(this.cityTree)

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadTplCityList(id){
            //配送设置列表
            request.get('/common/express/city/list?', {template_id:id,pagesize:99999}).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.regionList = ret.data.list;
                    for (const region of this.regionList) {
                       if (region.isSend===1) {
                           region.isSend=true
                       }else {
                           region.isSend=false
                       }
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });

        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        // 新增运费模板
        add(id, index) {
            this.mode = 'add';
            this.formExpressTpl = {}
            this.addVisible = true
            this.cityMenuIdList=[]
        },
        //编辑运费模板
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            const item = this.pageInfo.list[index];
            this.cityMenuIdList=item.freeAreaCodes
            console.log(this.cityMenuIdList)
            let time = [item.stime, item.etime]
            //处理时间
            this.formExpressTpl = Object.assign([], item);
            this.$set(this.formExpressTpl, 'time', time);
            this.addVisible = true;
            this.loadTplCityList(row.id)
        },


        regionRemove(index, row) {
            const item = this.regionList.splice(index,1)
        },
        // 删除运费模板
        delete(id, index) {

        },
        changeCount(num) {
            this.formExpressTpl.num = num
        },

        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/common/express/template/add' : '/common/express/template/edit';
                        //第一行为模板的默认参数
                        this.formExpressTpl.freeAreaCodes=JSON.stringify(this.getCityAllCheckedKeys())

                        let regionList =[]
                        for (const region of this.regionList) {
                            region.first = region.first * 1.0
                            region.first_price = region.first_price * 1.0
                            region.renew = region.renew * 1.0
                            region.renew_price = region.renew_price * 1.0
                            if (region.isSend) {
                                region.isSend=1;
                            }else {
                                region.isSend=0;
                            }
                            regionList.push(region)
                        }
                        this.formExpressTpl.customRegionList =JSON.stringify(regionList)

                        request.post(url, this.formExpressTpl).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('', this.formExpressTpl).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 可编辑单元格
        loseFcous(index, row) {
            this.$set(row, 'seen', false)
        },
        // 单元格编辑
        cellClick(row, column) {
            this.$set(row, 'seen', true)
        },


        append2CustomAreaTable(node,data,isSend) {
            console.log(node)
            console.log(data)
            let city = {
                areaName:data.label,
                areaCode: data.value,
                isSend: isSend,
                ty: this.formExpressTpl.ty,
                cityLevel: data.level,
                cityId: data.city_id,
                first:this.formExpressTpl.first,
                first_price:this.formExpressTpl.first_price,
                renew:this.formExpressTpl.renew,
                renew_price:this.formExpressTpl.renew_price,
            }
            if (data.parent_id===0){
                city.areaName+='(省/区)'
            }else {
                city.areaName+='(市)'
            }


            for (const region of this.regionList) {
                if (region.areaCode===city.areaCode){
                    this.$message.error(city.areaName+'已存在，请勿重复添加');
                    return false
                }
            }


            //添加到定制的邮费列表
            this.regionList.push(city)

            /*const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            console.log(index)
            */

        },
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* .form_table >>> thead .el-table-column--selection .cell {
    display: none;
} */
</style>